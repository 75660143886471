import { useApolloClient, useMutation } from '@apollo/client';
import { FormatCommandButtonEnum, FormatEnum } from '@components/create-widget/databox/DataboxTypes';
import SetSwitchIcon from '@components/widgets/W_CommandButton/utils/setSwitchIcon';
import { highlightSelectedStyle } from '@constants/constants';
import { msg } from '@constants/messages';
import { GET_DATA_SUBSCRIPTION } from '@graphql/queries';
import { UPDATE_WIDGET } from '@modules/widgets/api/UpdateWidget';
import ForwardIcon from '@mui/icons-material/Forward';
import { Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/system';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import useColors from '@utils/useColors';
import { WidgetTypesEnum } from '@utils/widgetTypes';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import ConfirmRpcExecution from '../../modals/ConfirmRpcExecution';
import WidgetEditControls from '../../WidgetEditControls';
import st from './style.module.css';

const W_CommandButton = (props) => {
  const { id, objectProperties, selected } = props;
  const theme = useTheme();

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;

  const client = useApolloClient();

  const title = getPropValue('settingsTitle');
  const size = getPropValue('settingsSize');
  const style = getPropValue('settingsStyle');

  const { getColorBasedOnStyle } = useColors();

  const textInitial = getPropValue('valueText') ?? msg.misc.na;
  const switchTextOff = getPropValue('valueSwitchtextoff') ?? msg.misc.na;
  const switchTextOn = getPropValue('valueSwitchtexton') ?? msg.misc.na;
  const switchStatus = getPropValue('valueStatus') ?? msg.misc.na;
  const textOnRpc = getPropValue('targetControlOn') ?? msg.misc.na;
  const textOffRpc = getPropValue('targetControlOff') ?? msg.misc.na;
  const objectId = getPropValue('valueAction')?.objectId;
  const buttonRpc = getPropValue('valueAction');

  const [text, setText] = useState(textInitial);
  const [, setAction] = useState(null);

  const valueCurrentColor = getPropValue('valueCurrentColor');

  const [colors, setColors] = useState(getColorBasedOnStyle(style, valueCurrentColor));

  const mode = getPropValue('settingsFormat') || 'icon_value';
  const valueActionRPC = getPropValue('valueAction')?.rpc;

  const isEditMode = useSelector((state) => state.settings.isEditMode);

  let arrowColor = colors.bg;

  if (style === 'lightontransparent') arrowColor = theme.palette.blue;
  else if (style === 'darkontransparent') arrowColor = theme.palette.white;

  const [editWidget] = useMutation(UPDATE_WIDGET);

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });
    const subscription = observer.subscribe(({ data }) => {
      // change number on widget
      if (data.Objects.relatedNode?.key === 'valueText') {
        setText(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === 'valueAction') {
        setAction(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === 'settingsStyle') {
        setColors(getColorBasedOnStyle(data.Objects.relatedNode?.value));
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  const handleRpc = () => {
    textOnRpc?.rpc && textOffRpc?.rpc
      ? ConfirmRpcExecution({
          handleSave: () => {
            toast
              .promise(
                client.mutate({
                  mutation: CREATE_CONTROL_EXECUTION,
                  variables: {
                    input: {
                      controlExecution: {
                        name: switchStatus ? textOffRpc?.rpc : textOnRpc?.rpc,
                        objectId: objectId,
                        params: switchStatus ? textOffRpc?.params : textOnRpc?.params,
                      },
                    },
                  },
                }),
                {
                  loading: 'Calling RPC...',
                  success: () => 'RPC called',
                  error: (err) => `${err.toString()}`,
                }
              )
              .then(() => handleChangeSwitch());
          },
          text: 'Execute action?',
        })
          .then()
          .catch()
      : toast.error('No control was found.');
  };

  const handleChangeSwitch = () => {
    toast
      .promise(
        editWidget({
          variables: {
            widgetId: id,
            values: [
              {
                propertyKey: 'valueStatus',
                value: !switchStatus,
              },
            ],
          },
        }),
        {
          loading: 'Loading...',
          success: () => `Status changed`,
          error: (err) => `${err.toString()}`,
        }
      )
      .then()
      .catch();
  };

  const handleCall = () => {
    valueActionRPC
      ? ConfirmRpcExecution({
          handleSave: () => {
            toast
              .promise(
                client.mutate({
                  mutation: CREATE_CONTROL_EXECUTION,
                  variables: {
                    input: {
                      controlExecution: {
                        name: buttonRpc?.rpc,
                        objectId: objectId,
                        params: buttonRpc?.params,
                      },
                    },
                  },
                }),
                {
                  loading: 'Calling RPC...',
                  success: () => 'RPC called',
                  error: (err) => `${err.toString()}`,
                }
              )
              .then(() => {
                editWidget({
                  variables: {
                    widgetId: id,
                    values: [
                      {
                        propertyKey: 'valueStatus',
                        value: !switchStatus,
                      },
                    ],
                  },
                }).then();
              });
          },
          text: 'Execute action?',
        })
          .then()
          .catch(() => {})
      : toast.error('No control was found.');
  };

  return (
    <div
      style={{
        backgroundColor: colors.bg,
        pointerEvents: isEditMode ? 'none' : 'auto',
        filter: selected ? highlightSelectedStyle : '',
      }}
      className={st.wrapperMain}
    >
      {[
        FormatCommandButtonEnum.button_text,
        FormatCommandButtonEnum.button,
        FormatEnum.value,
        FormatEnum.icon,
        FormatEnum.icon_value,
      ].includes(mode) && (
        <div
          className={st.wrapper}
          style={{
            paddingTop: mode === FormatCommandButtonEnum.button_text ? (size === 'medium' ? '20px' : '10px') : null,
          }}
        >
          {size === 'small' && (
            <Tooltip
              title={title ?? ''}
              disableTouchListener
              {...(title ? { disableHoverListener: false } : { disableHoverListener: true })}
            >
              <Fab
                size="large"
                aria-label="command"
                className={st.fabSmall}
                style={{
                  backgroundColor: colors.fg,
                }}
                onClick={handleCall}
              >
                <ForwardIcon
                  className={st.forwardIconSmall}
                  style={{
                    color: arrowColor,
                  }}
                />
              </Fab>
            </Tooltip>
          )}

          {size === 'medium' && (
            <Fab
              size="large"
              aria-label="command"
              className={st.fabMedium}
              style={{
                backgroundColor: colors.fg,
              }}
              onClick={handleCall}
            >
              <ForwardIcon
                className={st.forwardIconMedium}
                style={{
                  color: arrowColor,
                }}
              />
            </Fab>
          )}
        </div>
      )}

      {[FormatCommandButtonEnum.switcher_text, FormatCommandButtonEnum.switcher].includes(mode) && (
        <div className={st.wrapper} onClick={() => handleRpc()}>
          <div className={size === 'small' ? `${st.switcherSmallSize}` : `${st.switcherMediumSize}`}>
            <SetSwitchIcon mode={mode} colors={colors} style={style} size={size} switchStatus={switchStatus} />
          </div>
        </div>
      )}

      {[FormatCommandButtonEnum.switcher_text, FormatCommandButtonEnum.button_text, FormatEnum.icon_value].includes(
        mode
      ) && (
        <div className={st.switcherWrapper}>
          <Typography
            noWrap={true}
            style={{
              fontSize: size === 'medium' ? '45px' : '20px',
              fontWeight: size === 'small' ? '400' : '500',
              color: colors.fg,
            }}
          >
            {mode === FormatCommandButtonEnum.switcher_text
              ? switchStatus
                ? switchTextOn
                : switchTextOff
              : text || 'n/a'}
          </Typography>
        </div>
      )}
      <WidgetEditControls {...props} widgetType={WidgetTypesEnum.COMMAND_BUTTON} />
    </div>
  );
};

export default W_CommandButton;
