import { useApolloClient } from '@apollo/client';
import { ReactComponent as MenuDown } from '@assets/menu-down.svg';
import WidgetInitInfo from '@components/common/WidgetInitInfo';
import { ColumnValueType } from '@components/side-card/monitor-table/MonitorTableColumnModal';
import WidgetEditControls from '@components/WidgetEditControls';
import { COLOR_SCHEMAS, highlightSelectedStyle } from '@constants/constants';
import { GET_DATA_SUBSCRIPTION } from '@graphql/queries';
import { WidgetProps } from '@modules/reports/components/WidgetWrap';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import useColors from '@utils/useColors';
import { BASE_HEIGHT } from '@utils/widgetSizes';
import { format, parseISO } from 'date-fns';
import orderBy from 'lodash.orderby';
import sortBy from 'lodash.sortby';
import { useEffect, useMemo, useState } from 'react';

import { clsx } from 'clsx';
import st from './style.module.css';

const isNullableValue = (val: unknown) => {
  if (typeof val === 'string') {
    return val.trim() === '';
  } else if (!val) {
    return val !== 0 && val !== false;
  }
  return false;
};

type SortType = {
  type: 'asc' | 'desc';
  name: string;
};

type ValueType = {
  objectId: string;
  objectName: string;
  properties: Array<{
    id: string;
    isAlert: boolean;
    lastUpdate: string;
    name: string;
    schemaId: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
  }>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const W_MonitorTable = (props: WidgetProps) => {
  const { objectProperties, selected, id, name } = props;
  const client = useApolloClient();

  const objectPropertiesMap = useMemo(() => getPropertiesMap(objectProperties), [objectProperties]); // TODO: replace to parent component when all widgets will be written in TypeScript

  const [valueInitial, setValue] = useState<ValueType[]>(objectPropertiesMap?.['valueValue']?.value as ValueType[]);
  const columns: ColumnValueType[] = objectPropertiesMap['sourceColumns']?.value?.columns || [];

  const colors = [objectPropertiesMap['settingsStyle']?.value, null];
  const showTitle = objectPropertiesMap['settingsShowTitle']?.value;
  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(objectPropertiesMap['settingsStyle']?.value);

  const [sort, setSort] = useState<SortType>({
    type: 'asc',
    name: 'object',
  });

  const headers = useMemo(() => {
    if (valueInitial.length) {
      return sortBy(valueInitial[0].properties, (item) =>
        columns.findIndex((o) => o.property.id === item.schemaId)
      ).map((item) => {
        const columnProperty = columns.find((i) => i.property.id === item.schemaId)?.property;
        const aliasOrName: string = columnProperty?.alias || item.name || 'n/a';
        return columnProperty?.type && columnProperty.type !== 'value' ? `${aliasOrName} (Last update)` : aliasOrName;
      });
    }
    return [];
  }, [valueInitial, columns]);

  const getColorOfRow = (index: number) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return '';
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return '#F1F1F1';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  };

  const sortedData = useMemo(() => {
    if (!valueInitial.length) return [];

    const flat = valueInitial.map((item) => {
      const itemLocal = {
        ...item,
        properties: {} as Record<string, string>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        propertiesInternal: {} as Record<string, any>,
      };

      const sortedCollection = sortBy(item.properties, (prop) =>
        columns.findIndex((o) => o.property.id === prop.schemaId)
      );

      sortedCollection.forEach((property) => {
        const lastUpdate = format(parseISO(String(property?.lastUpdate)), 'dd-MM-yyyy HH:mm:ss');
        const type = columns.find((col) => col.property.id === property.schemaId)?.property?.type;
        itemLocal.properties[type === 'value' ? property.name : `${property.name} (Last update)`] =
          type === 'value' ? property.value : lastUpdate;
        itemLocal.propertiesInternal[`${property.name}_isAlert`] = property.isAlert;

        if (type !== 'value') {
          itemLocal.propertiesInternal[`${property.name} (Last update)`] = property.lastUpdate;
        }
      });

      return itemLocal;
    });

    if (sort.name && sort.type) {
      if (sort.name === 'object') {
        return orderBy(flat, ['objectName'], [sort.type]);
      }

      if (sort.name.includes('Last update')) {
        return orderBy(flat, [(o) => new Date(o.propertiesInternal[`${sort.name}`])], [sort.type]);
      }

      return orderBy(flat, [(o) => o.properties[`${sort.name}`]], [sort.type]);
    }

    return flat;
  }, [valueInitial, columns, sort]);

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === 'valueValue') {
        setValue(data.Objects.relatedNode?.value as ValueType[]);
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  return (
    <div
      className={st.tableContainer}
      style={{
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : '',
      }}
    >
      {showTitle && (
        <div
          className={st.titleContainer}
          style={{
            height: `${BASE_HEIGHT}px`,
          }}
        >
          <Box className={st.titleWrapper}>
            <Typography className={st.title} style={{ color: fgColor }} variant="h6">
              {name}
            </Typography>
          </Box>
        </div>
      )}
      <div className={clsx('force-scroll', st.tableWrapper)}>
        <Table size="small" className={st.table} aria-label="simple table" style={{ backgroundColor: 'transparent' }}>
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: bgColor,
            }}
          >
            {headers?.length > 0 && (
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                }}
              >
                <TableCell
                  className={st.tableCellHead}
                  onClick={() => {
                    setSort({
                      name: 'object',
                      type: 'object' === sort.name && sort.type === 'asc' ? 'desc' : 'asc',
                    });
                  }}
                  style={{ color: fgColor, textAlign: 'left' }}
                >
                  Object
                  {sort.type === 'desc' && sort.name === 'object' && <MenuDown className={st.menuIcon} />}
                  {sort.type === 'asc' && sort.name === 'object' && <MenuDown className={st.menuIconRotated} />}
                </TableCell>
                {headers?.map((header, index) => (
                  <TableCell
                    key={index}
                    onClick={() => {
                      setSort({
                        name: header,
                        type: header === sort.name && sort.type === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    align="right"
                    className={st.tableCellHead}
                    style={{ color: fgColor }}
                  >
                    {header}

                    {sort.type === 'desc' && sort.name === header && <MenuDown className={st.menuIcon} />}
                    {sort.type === 'asc' && sort.name === header && <MenuDown className={st.menuIconRotated} />}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {sortedData?.map((item, columnIndex) => (
              <TableRow
                key={columnIndex}
                style={{
                  height: `${BASE_HEIGHT}px`,
                  backgroundColor: getColorOfRow(columnIndex),
                }}
              >
                <TableCell scope="row" className={st.tableCell} style={{ color: fgColor }}>
                  {item.objectName}
                </TableCell>
                {Object.entries(item.properties).map(([key, value], index) => (
                  <TableCell
                    key={index}
                    align="right"
                    className={st.tableCell}
                    style={{
                      color: fgColor,
                      backgroundColor: item.propertiesInternal[`${key}_isAlert`] ? '#D50000' : '',
                    }}
                  >
                    {`${isNullableValue(value) ? 'n/a' : value}`}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {(columns?.length === 0 || valueInitial?.length === 0) && (
        <WidgetInitInfo fgColor={fgColor} infoText={'Make sure you added at least one column'} />
      )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_MonitorTable;
